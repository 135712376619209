<template>
    <div>
      <v-card v-if="!loading">
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        ><span class="text-h6">ファイルを削除しますか？</span>
        </v-toolbar>
      
        <v-card-text>
          削除したファイルは二度と復元できません。<br>
          <p class="red--text mb-1 ">   実行をするには <span v-if="this.deleteList.length > 1"> 「完全に削除」 と入力して下さい</span><span v-else>「削除」 と入力して下さい</span></p>
          {{deleteTitle}}
       
          <v-container>
            <v-row>
              <v-col
                cols="12"                     
                md="10"
              >
                <v-text-field
                  v-model="deleteItemName" 
                  :label="(this.deleteList.length > 1)? '完全に削除 と入力': '削除 と入力'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
         
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2"
              :disabled="loading"
              outlined
              color="#78909C"   
              @click="close">キャンセル
          </v-btn>
          <v-btn 
            color="#DA4453"   
            class="white--text"
            @click="deleteItem" 
            :disabled="this.deleteItemName!=checkName() "
            >削除
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card  v-else-if="isError" >
       <DialogError
          v-on:closeErrorEmit="close"
          text="エラーが発生し,削除が完了しませんでした。"
        />
       </v-card>

       <v-card v-else
            color="#4ECDC4"
            dark
          >
            <v-card-text>
              削除中...
              <br>

              <v-progress-linear
                indeterminate
                color="white"
               
              ></v-progress-linear>
            </v-card-text>
          </v-card>

      </div>
      

</template> 

<script>
import axios from "axios"
const DialogError = () => import('@/components/DialogError.vue');
export default {
  name: 'DialogDelete',
  props:["name","type","typeId","deleteList"],
  components: {
    DialogError
  },
  data () {
    return {
      deleteItemName : null,
      loading:false,
      isError:false,
    }
  },
  computed: {
    deleteTitle () {
      if(this.deleteList.length == 1 && this.name){
        return "ファイル名: "+this.name
      }else{
        return `${this.deleteList.length}個のファイルを選択中`
      }
    },
  },

  methods: {

    checkName(){
      if(this.deleteList.length > 1) return "完全に削除"
      else  return "削除"
    },

    //指定されたアイテムの削除
    deleteItem () {
      this.loading = true;
      let tmpList = [];
      Object.keys(this.deleteList).forEach(key =>tmpList.push(this.deleteList[key][this.typeId]))
      // console.log(tmpList)
      axios({
        method: "delete",
        url:
          `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.type}s`,
        headers: {
          "X-Api-Key": process.env.VUE_APP_KEY,
          "SessionId": this.$store.state.auth.login.sessionId,
          "Content-Type":"application/json" ,
          [this.typeId]:JSON.stringify(tmpList)
        },
      }).then(() => {
        // console.log(response)
        this.loading = false;
        this.$emit('deleteItemExeEmit',this.deleteList);
        this.$store.dispatch("setUserInfo",this.$store.state.auth.login ) //ユーザーデータの更新
        this.deleteItemName = null;

      }).catch(reason => {
        console.log(reason)
        this.isError = true;
      });    
     
    },

    close (){
      this.deleteItemName = null;
      this.isError = false;
      this.loading = false;
      this.$emit('closeDeleteEmit')
    }

 
  },
}

</script>
